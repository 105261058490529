import React from "react"
import LurraldebusWrapper from "./src/components/lurraldebusWrapper";

export const wrapPageElement = ({ element, props }) => (
  <LurraldebusWrapper {...props}>{element}</LurraldebusWrapper>
);

export const onServiceWorkerUpdateReady = () => window.location.reload(true);

export const onClientEntry = () => {
  console.log("Buruntzaldeabus Web")
}