// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-accesibilidad-js": () => import("./../../../src/pages/accesibilidad.js" /* webpackChunkName: "component---src-pages-accesibilidad-js" */),
  "component---src-pages-aviso-legal-js": () => import("./../../../src/pages/aviso-legal.js" /* webpackChunkName: "component---src-pages-aviso-legal-js" */),
  "component---src-pages-calcula-tu-ruta-js": () => import("./../../../src/pages/calcula-tu-ruta.js" /* webpackChunkName: "component---src-pages-calcula-tu-ruta-js" */),
  "component---src-pages-canales-de-contacto-js": () => import("./../../../src/pages/canales-de-contacto.js" /* webpackChunkName: "component---src-pages-canales-de-contacto-js" */),
  "component---src-pages-contacto-js": () => import("./../../../src/pages/contacto.js" /* webpackChunkName: "component---src-pages-contacto-js" */),
  "component---src-pages-descarga-aplicacion-js": () => import("./../../../src/pages/descarga-aplicacion.js" /* webpackChunkName: "component---src-pages-descarga-aplicacion-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-lineas-y-horarios-js": () => import("./../../../src/pages/lineas-y-horarios.js" /* webpackChunkName: "component---src-pages-lineas-y-horarios-js" */),
  "component---src-pages-normativa-js": () => import("./../../../src/pages/normativa.js" /* webpackChunkName: "component---src-pages-normativa-js" */),
  "component---src-pages-objetos-perdidos-js": () => import("./../../../src/pages/objetos-perdidos.js" /* webpackChunkName: "component---src-pages-objetos-perdidos-js" */),
  "component---src-pages-pago-de-infracciones-js": () => import("./../../../src/pages/pago-de-infracciones.js" /* webpackChunkName: "component---src-pages-pago-de-infracciones-js" */),
  "component---src-pages-parada-mas-cercana-js": () => import("./../../../src/pages/parada-mas-cercana.js" /* webpackChunkName: "component---src-pages-parada-mas-cercana-js" */),
  "component---src-pages-politica-de-cookies-js": () => import("./../../../src/pages/politica-de-cookies.js" /* webpackChunkName: "component---src-pages-politica-de-cookies-js" */),
  "component---src-pages-privacidad-js": () => import("./../../../src/pages/privacidad.js" /* webpackChunkName: "component---src-pages-privacidad-js" */),
  "component---src-pages-proximo-bus-js": () => import("./../../../src/pages/proximo-bus.js" /* webpackChunkName: "component---src-pages-proximo-bus-js" */),
  "component---src-pages-quejas-y-sugerencias-js": () => import("./../../../src/pages/quejas-y-sugerencias.js" /* webpackChunkName: "component---src-pages-quejas-y-sugerencias-js" */),
  "component---src-pages-quien-somos-js": () => import("./../../../src/pages/quien-somos.js" /* webpackChunkName: "component---src-pages-quien-somos-js" */)
}

